<script lang="ts">
  import { APP_URL, USER_SHOW_COUNT } from '../lib/config';
  import { Link } from 'svelte-navigator';
  import TopicPathVox from '../components/TopicPathVox.svelte';
  import LoadingVox from '../components/LoadingVox.svelte';
  import PageControl from '../components/PageControl.svelte';
  import CustomerOutline from '../components/CustomerOutline.svelte';
  import SearchForm from '../components/SearchForm.svelte';

  export let paged: number = 1;
  export let mode: string = '';

  const limit: number = USER_SHOW_COUNT;
  const url = new URL(location.href);
  const searchParams = url.searchParams;
  let isEmpty: boolean = false;
  let query: string = '';
  if (searchParams.has('s')) {
    query = searchParams.get('s');
  }

  $: users = (async () => {
    if (query) {
      paged = query ? 1 : paged;
      url.searchParams.set('s', query);
      window.history.pushState({}, '', `/customer/?s=${query}`);
    }
    const response = await fetch(
      `${APP_URL}get_users.php?number=${limit}&paged=${paged}&role=client&search=${query}`,
      {
        cache: 'no-cache',
      },
    );
    const res = await response.json();
    isEmpty = res.code !== 200 || !res.data.count ? true : false;
    return res.data;
  })();

  const pageTitle = '顧客管理';
</script>

<TopicPathVox {pageTitle} />

<div id="pageTitle">
  <h1>{pageTitle}</h1>
  <SearchForm bind:query mode="customer" />
</div>
<!-- /pageTitle -->

<div id="content">
  <div class="statusVox">
    {#await users then data}
      <div class="searchTotal">{data.total} 件</div>
    {/await}
    <div class="btnStyle01">
      <Link to="/customer/new">顧客情報登録</Link>
    </div>
  </div>
  <!-- /statusVox -->

  <div class="sectionVox">
    <div class="inner">
      {#if mode}
        <div class="completeMsg">
          {#if mode === 'add'}
            <p>顧客情報の登録が完了しました。</p>
          {:else if mode === 'delete'}
            <p>顧客情報の削除が完了しました。</p>
          {/if}
        </div>
      {/if}
      {#await users}
        <LoadingVox />
      {:then data}
        {#if isEmpty}
          <p class="nothing">顧客データが見つかりませんでした。</p>
        {:else}
          <CustomerOutline customers={data.users} />
          <!-- /customerList -->
          <PageControl
            {paged}
            maxPage={data.max_page}
            s={query}
            menu="customer"
          />
        {/if}
      {:catch error}
        <p>An error occurred!</p>
      {/await}
    </div>
  </div>
  <!-- /sectionVox -->
</div>

<!-- /content -->
