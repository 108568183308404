<script lang="ts">
  import { SyncLoader } from 'svelte-loading-spinners';

  export let mode = '';
</script>

<div class="loadingVox" class:full={mode === 'full'}>
  <SyncLoader size="60" color="#191919" unit="px" duration="1s" />
</div>

<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  :global(.loadingVox) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    &.full {
      min-height: 100vh;
    }
  }
  @media only screen and (max-width: (ext.$bp - 1)) {
    :global(.loadingVox) {
      height: 50px;
    }
  }
</style>
