<script>
  import LoadingVox from '../components/LoadingVox.svelte';

  location.href = '/karte/';
</script>

<div class="content" />

<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 50vh;
    p {
      text-align: center;
      color: ext.$errorTextColor;
    }
  }
</style>
