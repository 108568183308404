<script lang="ts">
  import { APP_URL } from '../lib/config';
  import { Link } from 'svelte-navigator';
  import TopicPathVox from '../components/TopicPathVox.svelte';
  import LoadingVox from '../components/LoadingVox.svelte';

  export let mode: string = '';

  let isEmpty: boolean = false;

  $: users = (async () => {
    const response = await fetch(
      `${APP_URL}get_users.php?role=manager&number=-1`,
      {
        cache: 'no-cache',
      },
    );
    const res = await response.json();
    isEmpty = res.code !== 200 || !res.data.count ? true : false;
    return res.data;
  })();

  const pageTitle = '担当者管理';
</script>

<TopicPathVox {pageTitle} />

<div id="pageTitle">
  <h1>{pageTitle}</h1>
</div>
<!-- /pageTitle -->

<div id="content">
  <div class="statusVox">
    <div class="searchTotal" />
    <div class="btnStyle01">
      <Link to="/editor/new">担当者情報登録</Link>
    </div>
  </div>
  <!-- /statusVox -->

  <div class="sectionVox">
    <div class="inner">
      {#if mode}
        <div class="completeMsg">
          {#if mode === 'add'}
            <p>担当者情報の登録が完了しました。</p>
          {:else if mode === 'delete'}
            <p>担当者情報の削除が完了しました。</p>
          {/if}
        </div>
      {/if}
      {#await users}
        <LoadingVox />
      {:then data}
        {#if isEmpty}
          <p class="nothing">担当者データが見つかりませんでした。</p>
        {:else}
          <div id="customerList">
            <table class="tableStyle01">
              <thead>
                <tr>
                  <th scope="row" class="id">ID</th>
                  <th scope="row" class="name">担当者名</th>
                  <th scope="row" class="mail">メールアドレス</th>
                  <th scope="row" class="edit">編集</th>
                </tr>
              </thead>
              <tbody>
                {#each data.users as user}
                  <tr class:is-disabled={!user.user_status}>
                    <td>{user.ID}</td>
                    <th>
                      {user.display_name}
                    </th>
                    <td>{user.user_email}</td>
                    <td class="edit">
                      <a href="/editor/{user.ID}/edit" class="icoEdit">
                        <img
                          src="/assets/images/content/content/ico_edit.svg"
                          alt="編集"
                        />
                      </a>
                    </td>
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
          <!-- /customerList -->
        {/if}
      {:catch error}
        <p>An error occurred!</p>
      {/await}
    </div>
  </div>
  <!-- /sectionVox -->
</div>

<!-- /content -->
<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  #customerList {
    .tableStyle01 {
      tbody {
        tr {
          &.is-disabled {
            th,
            td:not(.edit) {
              // background-color: ext.$borderColorLight;
              color: rgba(ext.$textColor, 0.25);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: (ext.$bp - 1)) {
    #customerList {
      overflow-x: auto;
      table {
        width: 1000px;
      }
    }
  }
</style>
