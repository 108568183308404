<script lang="ts">
  import { APP_URL } from '../lib/config';
  import { Link, navigate } from 'svelte-navigator';
  import TopicPathVox from '../components/TopicPathVox.svelte';
  import LoadingVox from '../components/LoadingVox.svelte';

  export let id: number;
  export let mode: string = 'new';

  let isEmptyPost: boolean = false;
  let isEditedPost: boolean = false;
  let isFailedEditedPost: boolean = false;
  let isSubmitting: boolean = false;
  let isEmptyEditors: boolean = false;
  let errorComment: string = '';
  const pageTitle: string =
    mode === 'new' ? 'カルテ情報登録' : 'カルテ情報編集';

  interface PostParams {
    id: number;
    // date: string;
    // author: string;
    manager: number;
    receiving_date: string;
    receiving_method: string;
    item: string;
    created: string;
    content: string;
    estimate_content: string;
    note: string;
    payment_date: string;
    estimated: string;
    payment: number;
    payment_method: string;
    delivery_date: string;
    delivery_method: string;
    receiving_limit: string;
    first_content: string;
  }

  let formParams: PostParams;
  $: formParams = {
    id: 0,
    // date: '',
    // author: '',
    manager: 0,
    receiving_date: '',
    receiving_method: '',
    item: '',
    created: '',
    content: '',
    estimate_content: '',
    note: '',
    payment_date: '',
    estimated: '',
    payment: 0,
    payment_method: '',
    delivery_date: '',
    delivery_method: '',
    receiving_limit: '',
    first_content: '',
  };

  $: post = (async () => {
    if (!id) return;
    const response = await fetch(`${APP_URL}get_karte.php?id=${id}`, {
      cache: 'no-cache',
    });
    const res = await response.json();
    if (res.code !== 200) {
      isEmptyPost = true;
    } else {
      formParams.id = res.data.ID;
      formParams.manager = res.data.manager;
      formParams.receiving_date = res.data.receiving_dateTime;
      formParams.receiving_method = res.data.receiving_method;
      formParams.item = res.data.item;
      formParams.created = res.data.created;
      formParams.content = res.data.content;
      formParams.estimate_content = res.data.estimate_content;
      formParams.note = res.data.note;
      formParams.payment_date = res.data.payment_dateTime;
      formParams.estimated = res.data.estimated_dateTime;
      formParams.payment = res.data.payment_native;
      formParams.payment_method = res.data.payment_method;
      formParams.delivery_date = res.data.delivery_dateTime;
      formParams.delivery_method = res.data.delivery_method;
      formParams.receiving_limit = res.data.receiving_limit;
      formParams.first_content = res.data.first_content;
    }
    return res.data;
  })();

  $: editors = (async () => {
    const response = await fetch(
      `${APP_URL}get_users.php?role=manager&number=-1`,
      {
        cache: 'no-cache',
      },
    );
    const res = await response.json();
    isEmptyEditors = res.code !== 200 || !res.data.count ? true : false;
    return res.data;
  })();
</script>

<TopicPathVox {pageTitle} />

<div id="pageTitle">
  <h1>{pageTitle}</h1>
</div>
<!-- /pageTitle -->

<div id="content">
  {#await post}
    <LoadingVox />
  {:then data}
    {#if isEmptyPost}
      <p class="nothing">カルテが見つかりませんでした。</p>
    {:else if isSubmitting}
      <LoadingVox />
    {:else}
      <div class="sectionVox">
        <div class="inner">
          {#if isEditedPost}
            <div class="completeMsg">
              <p>カルテの編集が完了しました。</p>
            </div>
          {/if}
          {#if isFailedEditedPost}
            <div class="errorMsg">
              <p>{errorComment}</p>
            </div>
          {/if}

          <div class="inputWrap">
            <div class="body">
              <div class="contVox half">
                <label>
                  <h2 class="headStyle03">顧客選択</h2>
                  <select name="entryRecordCustomer">
                    <option value="">選択してください</option>
                  </select>
                </label>
              </div>
              <!-- /contVox -->

              <h2 class="headStyle01 border"><span>修理内容</span></h2>
              <div class="contVox">
                <label>
                  <h3 class="headStyle03">品名</h3>
                  <input
                    type="text"
                    name="entryRecordRepairItem"
                    bind:value={formParams.item}
                  />
                </label>
              </div>
              <!-- /contVox -->

              <div class="columnVox">
                <div class="contVox half">
                  <label>
                    <h3 class="headStyle03">お預かり日</h3>
                    <input
                      type="date"
                      name="entryRecordRepairDate"
                      bind:value={formParams.receiving_date}
                    />
                  </label>
                </div>
                <!-- /contVox -->

                <div class="contVox half">
                  <label>
                    <h3 class="headStyle03">お預かり方法</h3>
                    <input
                      type="text"
                      name="entryRecordRepairOld"
                      bind:value={formParams.receiving_method}
                    />
                  </label>
                </div>
                <!-- /contVox -->
              </div>

              <div class="columnVox">
                <div class="contVox half">
                  <label>
                    <h3 class="headStyle03">年代</h3>
                    <input
                      type="text"
                      name="entryRecordRepairOld"
                      bind:value={formParams.created}
                    />
                  </label>
                </div>
                <!-- /contVox -->
              </div>

              <div class="contVox">
                <label>
                  <h3 class="headStyle03">修理内容</h3>
                  <textarea
                    name="entryRecordRepairContent"
                    bind:value={formParams.content}
                  />
                </label>
              </div>
              <!-- /contVox -->

              <div class="contVox">
                <label>
                  <h3 class="headStyle03">備考</h3>
                  <textarea
                    name="entryRecordRepairNote"
                    bind:value={formParams.note}
                  />
                </label>
              </div>
              <!-- /contVox -->

              <h2 class="headStyle01 border"><span>見積り・納品等</span></h2>
              <div class="columnVox">
                <div class="contVox half">
                  <label>
                    <h3 class="headStyle03">見積日</h3>
                    <input
                      type="date"
                      name="entryRecordEstimateDate"
                      bind:value={formParams.estimated}
                    />
                  </label>
                </div>
                <!-- /contVox -->

                <div class="contVox half">
                  <div>
                    <h3 class="headStyle03">修理合計代金</h3>
                    <div class="inputLabel after">
                      <input
                        type="number"
                        name="entryRecordEstimateCost"
                        bind:value={formParams.payment}
                      />
                      <span class="afterLabel">円 (税込)</span>
                    </div>
                  </div>
                </div>
                <!-- /contVox -->

                <div class="contVox half">
                  <label>
                    <h3 class="headStyle03">支払方法</h3>
                    <input
                      type="text"
                      name="entryRecordPayment"
                      bind:value={formParams.payment_method}
                    />
                  </label>
                </div>
                <!-- /contVox -->

                <div class="contVox half">
                  <label>
                    <h3 class="headStyle03">入金日</h3>
                    <input
                      type="date"
                      name="entryRecordPaymentDate"
                      bind:value={formParams.payment_date}
                    />
                  </label>
                </div>
                <!-- /contVox -->

                <div class="contVox half">
                  <label>
                    <h3 class="headStyle03">納品日</h3>
                    <input
                      type="date"
                      name="entryRecordDeliveryDate"
                      bind:value={formParams.delivery_date}
                    />
                  </label>
                </div>
                <!-- /contVox -->

                <div class="contVox half">
                  <label>
                    <h3 class="headStyle03">引渡し方法</h3>
                    <input
                      type="text"
                      name="entryRecordDelivery"
                      bind:value={formParams.delivery_method}
                    />
                  </label>
                </div>
                <!-- /contVox -->

                <div class="contVox half">
                  <label>
                    <h3 class="headStyle03">預かり期間</h3>
                    <input
                      type="text"
                      name="entryRecordStorage"
                      bind:value={formParams.receiving_limit}
                    />
                  </label>
                </div>
                <!-- /contVox -->
              </div>

              <div class="contVox">
                <label>
                  <h3 class="headStyle03">見積り用修理内容</h3>
                  <textarea
                    name="entryRecordEstimateNote"
                    bind:value={formParams.estimate_content}
                  />
                </label>
              </div>
              <!-- /contVox -->

              <h2 class="headStyle01 border"><span>画像</span></h2>
              <div class="contVox">
                <div class="explain">
                  <p>サムネイルをドラッグで順番の入れ替えができます。</p>
                </div>
                <div class="uploadImgList">
                  <div class="item">
                    <figure class="img">
                      <img
                        src="http://karte.suzuki-ningyo.localhost/static/assets/images/content/karte/detail/img01.jpg"
                        alt=""
                      />
                    </figure>
                    <div class="deleteBtn">
                      <a
                        href="javascript:void(0)"
                        id="js-deleteTrigger"
                        class="popupBtItem"
                        data-popup="popupWrapperImgDelete">画像を削除する</a
                      >
                    </div>
                  </div>
                  <!-- /item -->
                </div>
                <div class="uploadVox">
                  <p class="drop">ここにファイルをドロップ</p>
                  <p>または</p>
                  <div class="btnStyle01 long uploadBtn">
                    <div class="in">
                      <input
                        type="file"
                        name="entryRecordImages"
                        class="entryRecordImages"
                      />
                      <span>ファイルを選択</span>
                    </div>
                  </div>
                </div>
                <!-- /uploadVox -->
              </div>
              <!-- /contVox -->

              <h2 class="headStyle01 border">
                <span>初回お問い合わせ内容</span>
              </h2>
              <div class="contVox">
                <label>
                  <h3 class="headStyle03">本文</h3>
                  <textarea
                    name="entryRecordFirstNote"
                    bind:value={formParams.first_content}
                  />
                </label>
              </div>
              <!-- /contVox -->

              <div class="contVox">
                <label>
                  <h3 class="headStyle03">画像</h3>
                  <figure class="entryRecordFirstImg">
                    <img
                      src="http://karte.suzuki-ningyo.localhost/static/assets/images/content/karte/detail/img01.jpg"
                      alt=""
                    />
                    <div class="deleteBtn">
                      <a
                        href="javascript:void(0)"
                        id="js-deleteTrigger"
                        class="popupBtItem"
                        data-popup="popupWrapperImgDelete">画像を削除する</a
                      >
                    </div>
                  </figure>
                </label>
              </div>
              <!-- /contVox -->
            </div>
            <!-- /body -->

            <div class="sidebar">
              <div class="inputStatus">
                <h2 class="headStyle03">ステータス</h2>
                <dl class="visibility">
                  <dt>公開状態：</dt>
                  <dd>
                    <select name="entryVisibility">
                      <option value="draft">下書き</option>
                      <option value="private">非公開</option>
                      <option value="publish">公開</option>
                    </select>
                  </dd>
                </dl>
                <dl class="id">
                  <dt>顧客ID：</dt>
                  <dd>0001</dd>
                </dl>
                <dl class="timestamp">
                  <dt>作成日：</dt>
                  <dd><input type="date" name="entryDate" value="" /></dd>
                  <dd><input type="time" name="entryTime" value="" /></dd>
                </dl>
              </div>
              <!-- /inputStatus -->

              <div class="categorySelect">
                <h2 class="headStyle03">担当者</h2>
                <div class="voxInner">
                  {#await editors}
                    <LoadingVox />
                  {:then editorsData}
                    {#if !isEmptyEditors}
                      <ul>
                        {#each editorsData.users as editor}
                          <li>
                            <label
                              ><input
                                type="radio"
                                name="entryRecordCustomer"
                                bind:value={formParams.manager}
                              /><span class="name">{editor.display_name}</span
                              ></label
                            >
                          </li>
                        {/each}
                      </ul>
                    {:else}
                      <p class="none">
                        <Link to="/editor/new">担当者を作成してください</Link>
                      </p>
                    {/if}
                  {/await}
                </div>
              </div>
              <!-- /categorySelect -->

              <div class="btnStyle01 submitBtn">
                <button name="entrySubmit">更新</button>
              </div>

              <div class="deleteBtn">
                <a
                  href="javascript:void(0)"
                  id="js-deleteTrigger"
                  class="popupBtItem"
                  data-popup="popupWrapperDelete">顧客情報削除</a
                >
              </div>
            </div>
            <!-- /sidebar -->
          </div>
          <!-- /inputWrap -->
        </div>
      </div>
      <!-- /sectionVox -->
    {/if}
  {:catch error}
    <p>An error occurred!</p>
  {/await}
</div>

<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;
  /**
   * 登録 / 編集
   **/
  .entryRecordFirstImg {
    display: inline-block;
    max-width: 390px;
    position: relative;
    .deleteBtn {
      @include ext.deleteBtn();
    }
  }
</style>
