<script lang="ts">
  import { Link } from 'svelte-navigator';

  export let pageTitle: string;
</script>

<div id="topicPathVox">
  <div class="inner">
    <div class="breadcrumbs">
      <span class="item">
        <Link to="/">TOP</Link>
      </span>
      {#if pageTitle === 'カルテ詳細'}
        <span class="item">
          <Link to="/karte">カルテ管理</Link>
        </span>
      {:else if pageTitle === '顧客詳細' || pageTitle === '顧客情報登録' || pageTitle === '顧客情報編集'}
        <span class="item">
          <Link to="/customer">顧客管理</Link>
        </span>
      {:else if pageTitle === '担当者情報登録' || pageTitle === '担当者情報編集'}
        <span class="item">
          <Link to="/editor">担当者管理</Link>
        </span>
      {/if}
      <span class="item">
        {pageTitle}
      </span>
    </div>
  </div>
</div>
<!-- /topicPathVox -->
