<script lang="ts">
  import KarteCard from './KarteCard.svelte';
  export let posts = [];
</script>

<div class="karteList">
  {#each posts as post}
    <KarteCard {post} />
  {/each}
</div>

<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  .karteList {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  @media only screen and (max-width: (ext.$bp - 1)) {
    .karteList {
      justify-content: space-between;
      > .item {
        width: 48%;
        max-width: 100%;
        margin-bottom: 5%;
        &:not(:nth-of-type(4n)) {
          margin-right: 0;
        }
        .in,
        a {
          .txt {
            padding: 10px;
            .number {
              font-size: 1rem;
            }
            .title {
              font-size: 1.5rem;
              line-height: 1.5;
              margin-bottom: 10px;
            }
            time {
              font-size: 1rem;
            }
          }
          .customer {
            font-size: 1.2rem;
            padding: 5px 10px;
            .name {
              width: calc(100% - 60px);
            }
          }
        }
      }
    }
  }
</style>
