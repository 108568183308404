<script lang="ts">
  import { link } from 'svelte-navigator';

  export let paged: number = 1;
  export let maxPage: number = 1;
  export let menu: string = 'karte';
  export let s: string = '';

  const query = s ? `?s=${s}` : '';
</script>

<div class="pageControl">
  <span class="control prev">
    <a
      href="/{menu}/paged/{Number(paged) - 1}{query}"
      use:link
      class:is-disabled={paged == 1}>前のページ</a
    >
  </span>
  <ul class="pager">
    {#each Array(maxPage) as _, index (index)}
      <li>
        <a
          href="/{menu}/paged/{index + 1}{query}"
          use:link
          class:is-current={Number(index) + 1 == paged}>{index + 1}</a
        >
      </li>
    {/each}
  </ul>
  <span class="control next">
    <a
      href="/{menu}/paged/{Number(paged) + 1}{query}"
      use:link
      class:is-disabled={paged == maxPage}>次のページ</a
    >
  </span>
</div>

<!-- /pageControl -->
<style lang="scss">
  .pageControl {
    a {
      &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      &.is-current {
        font-weight: bold;
        pointer-events: none;
      }
    }
  }
</style>
