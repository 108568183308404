<script lang="ts">
  export let id: number;
</script>

<div class="btnArea">
  <div class="btnStyle01">
    <a href="/karte/{id}/estimate" target="_blank">
      <span>見積書作成</span>
    </a>
  </div>
  <div class="btnStyle01">
    <a href="/karte/{id}/deliveryslip" target="_blank">
      <span>納品書作成</span>
    </a>
  </div>
  <div class="btnStyle01">
    <a href="/karte/{id}/invoice" target="_blank">
      <span>請求書作成</span>
    </a>
  </div>
</div>
