<script lang="ts">
  import { APP_URL, ADMIN_URL } from '../lib/config';
  import TopicPathVox from '../components/TopicPathVox.svelte';
  import LoadingVox from '../components/LoadingVox.svelte';
  import CustomerOutline from '../components/CustomerOutline.svelte';
  import KarteDocuments from '../components/KarteDocuments.svelte';
  import Gallery from '../components/Gallery.svelte';
  import ImgPopup from '../components/ImgPopup.svelte';
  import FirstContact from '../components/FirstContact.svelte';

  export let id: number;

  let isEmpty: boolean = true;
  let isEmptyComment: boolean = false;
  let activeGalleryImg: number | null = null;
  let activeCommentImg: string = '';
  let activeContactImg: number | null = null;
  let isShowGalleryPopup: boolean = false;
  let isShowPostCommentPopup: boolean = false;
  let isShowDeleteCommentPopup: boolean = false;
  let isShowContactImgPopup: boolean = false;
  let isShowCommentImgPopup: boolean = false;
  let isSendMailComment: boolean = true;
  let deleteCommentID: number = 0;
  let isCommenting: boolean = false;
  let isCommented: boolean = false;
  let isFailedCommented: boolean = false;
  let commentMsg: string = '';
  let commentFiles;
  let commentFileInput: HTMLInputElement;
  let previewImage;
  let isShowPreview: boolean = false;
  let isDeleting: boolean = false;
  let isDeletedComment: boolean = false;
  let isFailedDeletedComment: boolean = false;
  let errorComment: string = '';
  const pageTitle: string = 'カルテ詳細';

  $: {
    if (
      isShowGalleryPopup ||
      isShowContactImgPopup ||
      isShowDeleteCommentPopup ||
      isShowPostCommentPopup ||
      isShowCommentImgPopup
    ) {
      document.body.classList.add('is-pOpen');
    } else {
      document.body.classList.remove('is-pOpen');
    }
  }

  $: post = (async () => {
    const response = await fetch(`${APP_URL}get_karte.php?id=${id}`, {
      cache: 'no-cache',
    });
    const res = await response.json();
    isEmpty = res.code !== 200 ? true : false;
    return res.data;
  })();

  $: comments = (async () => {
    const response = await fetch(`${APP_URL}get_comments.php?id=${id}`, {
      cache: 'no-cache',
    });
    const res = await response.json();
    // console.log(res);
    isEmptyComment = res.code !== 200 ? true : false;
    return res.data;
  })();

  const deleteComment = async (commentID: number) => {
    if (!commentID) return;
    isShowDeleteCommentPopup = false;
    isDeleting = true;
    clearMsg();

    const response = await fetch(
      `${APP_URL}delete_comment.php?id=${commentID}&post_id=${id}`,
      {
        cache: 'no-store',
      },
    );
    const res = await response.json();
    if (res.code === 200) {
      comments = res.data;
      deleteCommentID = 0;
      isDeleting = false;
      isDeletedComment = true;
    } else {
      isDeleting = false;
      isFailedDeletedComment = true;
    }
  };

  const postComment = async () => {
    if (commentMsg.trim().length < 2) return;
    isShowPostCommentPopup = false;
    isCommenting = true;
    clearMsg();

    const formData = new FormData();
    formData.append('id', String(id));
    formData.append('commentMsg', commentMsg);
    if (commentFiles) {
      formData.append('commentFiles', commentFiles[0]);
    }
    formData.append('isSendMail', String(isSendMailComment));

    const response = await fetch(`${APP_URL}post_comment.php`, {
      method: 'POST',
      // headers: [['content-type', 'multipart/form-data']],
      body: formData,
      cache: 'no-store',
    });
    const res = await response.json();
    // console.log(res);
    if (res.code === 200) {
      comments = res.data;
      isCommenting = false;
      isCommented = true;
      isEmptyComment = false;
      commentMsg = '';
      if (commentFiles) clearFile();
    } else {
      isCommenting = false;
      isFailedCommented = true;
      errorComment = res.data;
    }
  };

  const clearMsg = (): void => {
    isDeletedComment = false;
    isFailedDeletedComment = false;
    isCommented = false;
    isFailedCommented = false;
  };

  const handleShowPopup = (e) => {
    switch (e.detail.popup) {
      case 'galleryImg':
        isShowGalleryPopup = true;
        break;
      case 'contactImg':
        isShowContactImgPopup = true;
        break;
    }
  };

  const handleClosePopup = (e) => {
    switch (e.detail.popup) {
      case 'galleryImg':
        isShowGalleryPopup = false;
        activeGalleryImg = null;
        break;
      case 'commentImg':
        isShowCommentImgPopup = false;
        break;
      case 'contactImg':
        isShowContactImgPopup = false;
        activeContactImg = null;
        break;
    }
  };

  const handleChangeActiveGalleryImg = (e) => {
    activeGalleryImg = e.detail.index;
  };

  const handleNextGalleryImg = (e) => {
    activeGalleryImg = e.detail.index;
  };

  const handlePrevGalleryImg = (e) => {
    activeGalleryImg = e.detail.index;
  };

  const handleChangeActiveContactImg = (e) => {
    activeContactImg = e.detail.index;
  };

  const handleNextContactImg = (e) => {
    activeContactImg = e.detail.index;
  };

  const handlePrevContactImg = (e) => {
    activeContactImg = e.detail.index;
  };

  const showPreview = (): void => {
    if (commentFiles?.[0]) {
      isShowPreview = true;
      const reader = new FileReader();
      reader.addEventListener('load', function () {
        previewImage = reader.result;
      });
      reader.readAsDataURL(commentFiles[0]);
    } else {
      isShowPreview = false;
    }
  };

  const clearFile = (): void => {
    if (commentFileInput) commentFileInput.value = '';
    isShowPreview = false;
    previewImage = false;
  };
</script>

<TopicPathVox {pageTitle} />

{#await post}
  <LoadingVox />
{:then data}
  <div id="pageTitle">
    <h1>{pageTitle}</h1>
    {#if !isEmpty}
      <KarteDocuments {id} />
    {/if}
  </div>
  <!-- /pageTitle -->

  <div id="content">
    {#if isEmpty}
      <p class="nothing">カルテが見つかりませんでした。</p>
    {:else}
      <section class="sectionVox">
        <header>
          <h2 class="headStyle01">顧客情報</h2>
        </header>
        <div class="inner">
          <div id="customerList">
            <CustomerOutline customers={[data.customer_info]} />
          </div>
          <!-- /customerList -->
        </div>
      </section>
      <!-- /sectionVox -->

      <section class="sectionVox">
        <header>
          <h2 class="headStyle01">修復詳細</h2>
          <div class="iconBtn edit">
            <a href="{ADMIN_URL}wp-admin/post.php?post={data.id}&action=edit">
              <span>カルテ編集</span>
            </a>
          </div>
        </header>
        <div class="inner">
          <div class="karteDetail">
            <div class="txt">
              <div class="contVox">
                <table class="tableStyle02">
                  <tbody>
                    <tr>
                      <th>カルテID</th>
                      <td>{data.id}</td>
                    </tr>
                    <tr>
                      <th>カルテ作成日</th>
                      <td>{data.date} (作成者: {data.author})</td>
                    </tr>
                    <tr>
                      <th>担当者名</th>
                      <td>{data.manager_lastname} {data.manager_firstname}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /contVox -->

              <div class="contVox" id="repair">
                <h3 class="headStyle02">修理内容</h3>
                <table class="tableStyle02">
                  <tbody>
                    <tr>
                      <th>お預かり日</th>
                      <td>{data.receiving_date}</td>
                    </tr>
                    <tr>
                      <th>お預かり方法</th>
                      <td>{data.receiving_method}</td>
                    </tr>
                    <tr>
                      <th>品名</th>
                      <td>{data.item}</td>
                    </tr>
                    <tr>
                      <th>年代</th>
                      <td>{data.created}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="tableStyle02 vertical">
                  <tbody>
                    <tr>
                      <th>修理内容</th>
                      <td class="htmlContent">
                        {@html data.content}
                      </td>
                    </tr>
                    <tr>
                      <th>見積り用修理内容</th>
                      <td class="htmlContent">
                        {@html data.estimate_content}
                      </td>
                    </tr>
                    <tr>
                      <th>備考</th>
                      <td>
                        {@html data.note}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /contVox -->

              <div class="contVox" id="payment">
                <div class="columnVox middle">
                  <h3 class="headStyle02">修理費用</h3>
                  {#if data.payment_date}
                    <p class="status finished">ご入金済</p>
                  {:else}
                    <p class="status unpaid">未入金</p>
                  {/if}
                </div>
                <div class="columnVox blockSP">
                  <div class="column">
                    <table class="tableStyle02">
                      <tbody>
                        <tr>
                          <th>見積日</th>
                          <td>{data.estimated}</td>
                        </tr>
                        <tr>
                          <th>修理合計代金</th>
                          <td>{data.payment ? `${data.payment}円` : ''}</td>
                        </tr>
                        <tr>
                          <th>支払方法</th>
                          <td>{data.payment_method}</td>
                        </tr>
                        <tr>
                          <th>入金日</th>
                          <td>{data.payment_date}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="column">
                    <table class="tableStyle02">
                      <tbody>
                        <tr>
                          <th>納品日</th>
                          <td>{data.delivery_date}</td>
                        </tr>
                        <tr>
                          <th>引渡し方法</th>
                          <td>{data.delivery_method}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /contVox -->
            </div>
            <div class="galleries">
              {#if data.gallery.length}
                <Gallery
                  images={data.gallery}
                  {activeGalleryImg}
                  on:showPopup={handleShowPopup}
                  on:changeActiveGalleryImg={handleChangeActiveGalleryImg}
                />
              {/if}
            </div>
            <!-- /galleries -->
          </div>
          <!-- /karteDetail -->
        </div>
      </section>
      <!-- /sectionVox -->

      <div class="sectionVox">
        <div class="inner">
          {#if isDeleting}
            <LoadingVox />
          {:else}
            <div class="contactMsgList">
              {#await comments}
                <LoadingVox />
              {:then data}
                {#if !isEmptyComment}
                  {#each data as comment, index}
                    <div class="item">
                      <h3 class="title">
                        <time datetime={comment.datetime}>{comment.date}</time>
                      </h3>
                      <p>
                        {@html comment.content}
                      </p>
                      {#if comment.media.id}
                        <div class="fileVox">
                          <dl>
                            <dt>添付ファイル</dt>
                            <dd>
                              {#if comment.media.media_type === 'file'}
                                <a href={comment.media.url} target="_blank">
                                  {comment.media.filename}
                                </a>
                              {:else}
                                <a
                                  href={comment.media.url}
                                  on:click|preventDefault={() => {
                                    activeCommentImg = comment.media.url;
                                    isShowCommentImgPopup = true;
                                  }}
                                  ><span class="img"
                                    ><img
                                      src={comment.media.thumbnail}
                                      alt={comment.media.title}
                                    /></span
                                  ><span class="title">
                                    {comment.media.filename}
                                  </span>
                                </a>
                              {/if}
                            </dd>
                          </dl>
                        </div>
                      {/if}
                      <footer>
                        <p>{comment.author_name}</p>
                      </footer>
                      <div class="deleteBtn">
                        <a
                          href="xxx"
                          class="popupBtItem"
                          on:click|preventDefault={() => {
                            isShowDeleteCommentPopup = true;
                            deleteCommentID = comment.id;
                          }}
                        >
                          メッセージを削除する
                        </a>
                      </div>
                    </div>
                    <!-- /item -->
                  {/each}
                {/if}
              {/await}
              {#if isDeletedComment}
                <div class="completeMsg">
                  <p>メッセージを削除しました。</p>
                </div>
              {/if}
              {#if isFailedDeletedComment}
                <div class="errorMsg">
                  <p>メッセージの削除に失敗しました。</p>
                </div>
              {/if}
            </div>
            <!-- /contactMsgList -->
          {/if}

          {#if isCommenting}
            <LoadingVox />
          {:else}
            <div class="contactMsgSend" class:is-loading={isCommenting}>
              {#if isCommented}
                <div class="completeMsg">
                  <p>メッセージを登録しました。</p>
                </div>
              {/if}
              {#if isFailedCommented}
                <div class="errorMsg">
                  <p>{errorComment}</p>
                </div>
              {/if}
              <h3 class="headStyle01">メッセージ</h3>
              <textarea
                name="entryContactMsg"
                class="entryContactMsg"
                bind:value={commentMsg}
              />
              <div class="fileVox">
                <input
                  type="file"
                  name="commentFiles"
                  accept="application/pdf,image/*,application/msword,application/msexcel"
                  bind:files={commentFiles}
                  bind:this={commentFileInput}
                  on:change={showPreview}
                />
                <button on:click|preventDefault={clearFile}>クリア</button>
                <p class="mT20">
                  登録可能ファイル: gif,png,jpg,pdf,xls,xlsx,doc,docx
                </p>
                {#if isShowPreview && previewImage && commentFiles?.[0]}
                  <figure class="previewImg">
                    <img src={previewImage} alt="Preview" />
                    <figcaption>
                      {commentFiles[0].name}<br /><span class="size"
                        >({commentFiles[0].size}Byte)</span
                      >
                    </figcaption>
                  </figure>
                {/if}
              </div>
              <div class="btnArea">
                <div class="btnStyle01 long">
                  <button
                    name="entrySubmit"
                    on:click={() => {
                      if (commentMsg !== '') isShowPostCommentPopup = true;
                    }}>送信する</button
                  >
                </div>
              </div>
              <!-- /btnArea -->
            </div>
            <!-- /contactMsgSend -->
          {/if}
        </div>
      </div>
      <!-- /sectionVox -->

      <section class="sectionVox">
        <header>
          <h2 class="headStyle01">初回お問い合わせ内容</h2>
        </header>
        <div class="inner">
          <FirstContact
            images={data.first_img}
            text={data.first_content}
            on:showPopup={handleShowPopup}
            {activeContactImg}
            on:changeActiveContactImg={handleChangeActiveContactImg}
          />
        </div>
      </section>
      <!-- /sectionVox -->

      {#if isShowGalleryPopup}
        <ImgPopup
          activeImg={data.gallery[activeGalleryImg].full}
          {activeGalleryImg}
          popup="galleryImg"
          gallery={data.gallery}
          on:closePopup={handleClosePopup}
          on:nextGalleryImg={handleNextGalleryImg}
          on:prevGalleryImg={handlePrevGalleryImg}
        />
      {/if}

      {#if isShowPostCommentPopup}
        <div class="popupWrapper vertical" id="popupWrapperPostComment">
          <div class="contentWrapper">
            <div class="content">
              <div class="postMsgVox">
                <h2 class="headStyle01">メッセージを登録します</h2>
                <div class="msgVox">
                  {@html commentMsg.replace('\n', '<br>')}
                  {#if isShowPreview && previewImage && commentFiles?.[0]}
                    <figure class="previewImg">
                      <img src={previewImage} alt="Preview" />
                      <figcaption>
                        {commentFiles[0].name}<br /><span class="size"
                          >({commentFiles[0].size}Byte)</span
                        >
                      </figcaption>
                    </figure>
                  {/if}
                </div>
                <div class="alignCenter">
                  <input
                    type="checkbox"
                    name="isSendMail"
                    bind:checked={isSendMailComment}
                    id="entrySendMail"
                  />
                  <label for="entrySendMail">顧客へメールを送信する</label>
                </div>
                <div class="btnArea">
                  <div class="btnStyle01 back white closeBtn">
                    <button
                      on:click|preventDefault={() =>
                        (isShowPostCommentPopup = false)}
                    >
                      <span>キャンセル</span>
                    </button>
                  </div>
                  <div class="btnStyle01">
                    <button on:click|preventDefault={postComment}>
                      <span>送信する</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- /deleteMsgVox -->
            </div>
          </div>
        </div>
      {/if}

      {#if isShowDeleteCommentPopup}
        <div class="popupWrapper vertical" id="popupWrapperDeleteComment">
          <div class="contentWrapper">
            <div class="content">
              <div class="deleteMsgVox">
                <h2 class="headStyle01">メッセージを削除します</h2>
                <div class="btnArea">
                  <div class="btnStyle01 back white closeBtn">
                    <button
                      on:click|preventDefault={() =>
                        (isShowDeleteCommentPopup = false)}
                      ><span>キャンセル</span></button
                    >
                  </div>
                  <div class="btnStyle01">
                    <button
                      on:click|preventDefault={() =>
                        deleteComment(deleteCommentID)}
                      ><span>削除する</span></button
                    >
                  </div>
                </div>
              </div>
              <!-- /deleteMsgVox -->
            </div>
          </div>
        </div>
      {/if}

      {#if isShowCommentImgPopup}
        <ImgPopup
          activeImg={activeCommentImg}
          popup="commentImg"
          on:closePopup={handleClosePopup}
        />
      {/if}

      {#if isShowContactImgPopup}
        <ImgPopup
          activeImg={data.first_img[activeContactImg].full}
          activeGalleryImg={activeContactImg}
          popup="contactImg"
          gallery={data.first_img}
          on:closePopup={handleClosePopup}
          on:nextGalleryImg={handleNextContactImg}
          on:prevGalleryImg={handlePrevContactImg}
        />
      {/if}
    {/if}
  </div>
{:catch error}
  <p>An error occurred!</p>
{/await}

<!-- /content -->
<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  // 修正詳細
  .karteDetail {
    display: flex;
    justify-content: space-between;
    .contVox {
      .tableStyle02 {
        &:last-of-type {
          border-top: none;
        }
        > tbody > tr {
          > th {
            width: 10em;
          }
        }
      }
    }
    > .txt {
      width: 50%;
      max-width: 600px;
      #payment {
        .headStyle02 {
          display: inline-block;
        }
      }
    }
    > .galleries {
      width: 45.8%;
      max-width: 550px;
    }
  }
  // メッセージ一覧
  .contactMsgList {
    &.is-loading {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 100;
      }
    }
    > .item {
      border: 1px solid ext.$borderColor;
      padding: 20px;
      background: #fff;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      > .title {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.5;
        margin-bottom: 20px;
      }
      .fileVox {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px dashed rgba(ext.$textColor, 0.75);
        dl {
          display: flex;
          align-items: center;
          dt {
            width: 7em;
            font-weight: 500;
            &::after {
              content: ':';
            }
          }
          dd {
            margin: 0;
            a {
              display: flex;
              .img {
                img {
                  width: 32px;
                  height: 32px;
                  object-fit: cover;
                }
              }
              .title {
                display: inline-block;
                margin-left: 0.5em;
              }
            }
          }
        }
      }
      footer {
        text-align: right;
        p {
          display: inline-block;
          position: relative;
          color: rgba(ext.$textColor, 0.75);
          font-style: italic;
          padding-left: 4.5em;
          &::before {
            content: '';
            width: 4em;
            height: 1px;
            background-color: rgba(ext.$textColor, 0.75);
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
      }
      .deleteBtn {
        @include ext.deleteBtn(40);
        top: 0;
        right: 0;
        a,
        button {
          background-size: 20px auto;
        }
      }
    }
  }
  // メッセージ送信
  .contactMsgSend {
    &.is-loading {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 100;
      }
    }
    margin-top: 50px;
    .entryContactMsg {
      font-size: 1.6rem;
      width: 100%;
      height: 200px;
      padding: 15px 20px;
      border: 1px solid ext.$borderColor;
      border-radius: 5px;
      outline: 0;
    }
    .btnArea {
      text-align: center;
      margin-top: 30px;
    }
    .fileVox {
      text-align: center;
      padding: 1em 0;
    }
  }
  .popupWrapper {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    .closeVox {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
    }
    .content {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &#popupWrapperDeleteComment,
    &#popupWrapperPostComment {
      .content {
        height: 100vh;
      }
    }
  }
  .previewImg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    img {
      width: 100px;
    }
    figcaption {
      font-size: 1.4rem;
      margin-left: 10px;
      .size {
        font-size: 1.2rem;
      }
    }
  }
  @media only screen and (max-width: (ext.$bp - 1)) {
    #customerList {
      overflow-x: auto;
      table {
        width: 1000px;
      }
    }
    // 修正詳細
    .karteDetail {
      display: block;
      > .txt {
        width: auto;
        max-width: 100%;
      }
      > .galleries {
        width: auto;
        max-width: 100%;
      }
    }
    #repair {
      .tableStyle02.vertical {
        border-top: none;
      }
    }
    #payment {
      .columnVox {
        > .column:not(:last-of-type) {
          .tableStyle02:last-of-type {
            tr:last-of-type {
              th,
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    // メッセージ一覧
    .contactMsgList {
      > .item {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        > .title {
          font-size: 1.8rem;
          margin-bottom: 10px;
        }
      }
    }
    // メッセージ送信
    .contactMsgSend {
      margin-top: 25px;
      .entryContactMsg {
        height: 150px;
        padding: 10px 15px;
      }
    }
  }
</style>
