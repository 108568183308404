<script lang="ts">
  import { isLoggedIn, userName } from './store/store';
  import { APP_URL } from './lib/config';
  import { Router, Link, Route, navigate } from 'svelte-navigator';
  import Customers from './pages/Customers.svelte';
  import Home from './pages/Home.svelte';
  import Karten from './pages/Karten.svelte';
  import KarteDetail from './pages/KarteDetail.svelte';
  import KarteEdit from './pages/KarteEdit.svelte';
  import KarteEstimate from './pages/KarteEstimate.svelte';
  import CustomerDetail from './pages/CustomerDetail.svelte';
  import CustomerEdit from './pages/CustomerEdit.svelte';
  import KarteDeliveryslip from './pages/KarteDeliveryslip.svelte';
  import KarteInvoice from './pages/KarteInvoice.svelte';
  import Editors from './pages/Editors.svelte';
  import EditorEdit from './pages/EditorEdit.svelte';
  import Login from './pages/Login.svelte';
  import LoadingVox from './components/LoadingVox.svelte';

  // let userName: string = '';
  let isEmptyUser: boolean = false;
  const url = new URL(location.href);
  const searchParams = url.searchParams;
  let query: string = '';
  if (searchParams.has('s')) {
    query = searchParams.get('s');
  }

  $: currentUser = (async () => {
    if ($isLoggedIn) return;
    const response = await fetch(`${APP_URL}get_current_user.php`, {
      cache: 'no-cache',
    });
    const res = await response.json();
    // if (IS_PRODUCTION) {
    if (
      res.code === 200 &&
      (res.data.role === 'manager' || res.data.role === 'administrator')
    ) {
      userName.set(res.data.display_name);
      isLoggedIn.set(true);
      if (url.pathname !== '/') {
        navigate(url.pathname, { replace: true });
        // location.href = url.pathname;
      } else {
        // navigate('/karte/', { replace: true });
        location.href = url.pathname;
      }
      // if (!query) {
      //   navigate('/karte/', { replace: true });
      // }
      // location.href = '/karte/';
    } else {
      isEmptyUser = true;
      navigate('/login/', { replace: true });
    }
    // }
    return res.data;
  })();
</script>

<Router>
  <div id="wrapper">
    <header id="siteHeader">
      <div class="inner">
        <span id="siteLogo">
          <Link to="/">
            <img
              src="/assets/images/global/logo.svg"
              alt="鈴木人形 電子カルテシステム"
            />
          </Link>
        </span>
        <div id="gNavWrapper">
          <div class="userName">{$userName}</div>
          <nav id="gNav">
            <ul class="main">
              <li class="karte">
                <Link to="/karte/"><span>カルテ管理</span></Link>
              </li>
              <li class="customer">
                <Link to="/customer/"><span>顧客管理</span></Link>
              </li>
              <li class="other">
                <a href="javascript:void(0)">その他</a>
                <ul class="sub">
                  <li class="editor">
                    <Link to="/editor/">
                      <span>担当者管理</span>
                    </Link>
                  </li>
                  <li class="website">
                    <a href="http://www.suzuki-ningyo.com/" target="_blank"
                      ><span>公式サイト</span></a
                    >
                  </li>
                  <li class="logout">
                    <Link to="/login/logout"><span>ログアウト</span></Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <!-- /gNav -->
        </div>
        <!-- /gNavWrapper -->
        <a href="javascript:void(0)" id="gNavOpener" class="gNavOpener">
          <span><!-- --></span>
          <span><!-- --></span>
          <span><!-- --></span>
        </a><!-- /gNavOpener -->
      </div>
    </header>
    <!-- /siteHeader -->
    <div id="container">
      <main id="main">
        {#await currentUser}
          <LoadingVox mode="full" />
        {:then data}
          <Route path="karte/*">
            <Route path="/">
              <Karten />
            </Route>
            <Route path="paged/*">
              <Route path="/">
                <Karten />
              </Route>
              <Route path=":paged" component={Karten} />
            </Route>
            <Route path="new" component={KarteEdit} mode="new" />
            <Route path=":id" component={KarteDetail} />
            <Route path=":id/edit" component={KarteEdit} mode="edit" />
            <Route path=":id/estimate" component={KarteEstimate} />
            <Route path=":id/deliveryslip" component={KarteDeliveryslip} />
            <Route path=":id/invoice" component={KarteInvoice} />
          </Route>
          <Route path="customer/*">
            <Route path="/">
              <Customers />
            </Route>
            <Route path="add" component={Customers} mode="add" />
            <Route path="delete" component={Customers} mode="delete" />
            <Route path="paged/*">
              <Route path="/">
                <Customers />
              </Route>
              <Route path=":paged" component={Customers} />
            </Route>
            <Route path="new" component={CustomerEdit} mode="new" />
            <Route path=":id" component={CustomerDetail} />
            <Route path=":id/edit" component={CustomerEdit} mode="edit" />
          </Route>
          <Route path="editor/*">
            <Route path="/">
              <Editors />
            </Route>
            <Route path="add" component={Editors} mode="add" />
            <Route path="delete" component={Editors} mode="delete" />
            <Route path="new" component={EditorEdit} mode="new" />
            <Route path=":id/edit" component={EditorEdit} mode="edit" />
          </Route>
          <Route path="login/*">
            <Route path="/">
              <Login />
            </Route>
            <Route path="logout" component={Login} mode="logout" />
          </Route>
          <Route>
            <Home />
          </Route>
        {:catch error}
          <p>An error occurred!</p>
        {/await}
      </main>
    </div>
    <!-- /container -->
  </div>
  <!-- /wrapper -->
  <footer id="siteFooter">
    <div class="inner">
      <div id="pageTopVox">
        <a href="javascript:void(0)">PAGE TOP</a>
      </div>
      <!-- /pageTopVox -->
      <small id="copyright">Copyright &copy; SUZUKI DOLLS CO.,LTD</small>
    </div>
  </footer>
  <!-- /siteFooter -->
</Router>

<style lang="scss">
  :global(*) {
    outline: none;
  }
</style>
