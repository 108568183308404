<script lang="ts">
  import { isLoggedIn, userName } from '../store/store';
  import { APP_URL, ADMIN_URL } from '../lib/config';
  import { navigate } from 'svelte-navigator';
  import LoadingVox from '../components/LoadingVox.svelte';

  export let mode: string = '';

  let isSubmitting: boolean = false;
  let isFailed: boolean = false;
  let isLogout: boolean = false;
  let errorComment: string = '';
  let entryLogin: string = '';
  let entryPassword: string = '';

  const handleSubmit = async () => {
    if (!entryLogin || !entryPassword) return;
    isSubmitting = true;
    isFailed = false;
    isLogout = false;

    const formData = new FormData();
    formData.append('entryLogin', entryLogin);
    formData.append('entryPassword', entryPassword);

    const response = await fetch(`${APP_URL}login.php`, {
      method: 'POST',
      // headers: [['content-type', 'multipart/form-data']],
      body: formData,
      cache: 'no-store',
    });
    const res = await response.json();
    if (res.code !== 200) {
      isFailed = true;
    } else {
      if (res.data.status !== 'successed') {
        isFailed = true;
        errorComment = res.data.content;
      } else {
        navigate('/', { replace: true });
      }
    }
    isSubmitting = false;
  };

  if (mode === 'logout') {
    isSubmitting = true;
    (async () => {
      const response = await fetch(`${APP_URL}logout.php`, {
        cache: 'no-store',
      });
      const res = await response.json();
      isLogout = res.code === 200 ? true : false;
      isSubmitting = false;
      isLoggedIn.set(false);
      userName.set('');
    })();
  }

  if (document.getElementById('siteHeader'))
    document.getElementById('siteHeader').remove();
  if (document.getElementById('wrapper'))
    document.getElementById('wrapper').style.paddingTop = `0px`;
  if (document.getElementById('container'))
    document.getElementById('container').style.paddingBottom = `0px`;
  if (document.getElementById('main')) {
    Object.assign(document.getElementById('main').style, {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 'calc(100vh - 40px)',
    });
  }
</script>

<div id="loginVox">
  <h1 id="loginTitle">
    <img src="/assets/images/global/logo_b.svg" alt="鈴木人形" /><br />
    電子カルテシステム <br class="pcDspNone" />ログイン
  </h1>

  {#if isSubmitting}
    <LoadingVox />
  {:else}
    {#if isFailed}
      <div class="errorMsg">
        <p>{@html errorComment}</p>
      </div>
    {/if}
    {#if isLogout}
      <div class="completeMsg">
        <p>ログアウトしました。</p>
      </div>
    {/if}

    <form action="/" method="post" on:submit|preventDefault={handleSubmit}>
      <div class="formVox">
        <div class="item user">
          <input
            type="text"
            name="entryLogin"
            class="loginInput"
            placeholder="ユーザ名"
            bind:value={entryLogin}
          />
        </div>
        <div class="item password">
          <input
            type="password"
            name="entryPassword"
            class="loginInput"
            placeholder="パスワード"
            bind:value={entryPassword}
          />
        </div>
        <div class="btnStyle01 long submitBtn">
          <button name="entryLoginBtn">ログイン</button>
        </div>
      </div>
      <!-- /formVox -->
    </form>
  {/if}
  <p>
    <a href="{ADMIN_URL}wp-login.php?action=lostpassword"
      >パスワードを忘れた方はこちら</a
    >
  </p>
</div>

<!-- /loginVox -->
<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  .description {
    display: inline-block;
    text-align: left;
    max-width: 500px;
    margin-bottom: 30px;
    > p {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }
  #loginVox {
    text-align: center;
    #loginTitle {
      font-size: 3.4rem;
      font-weight: bold;
      letter-spacing: 0.1em;
      line-height: 1.3;
      margin-bottom: 50px;
      img {
        width: 200px;
        margin-bottom: 20px;
      }
    }
    .formVox {
      width: 400px;
      margin: 0 auto 50px auto;
      > .item {
        @include ext.formParts();
        display: flex;
        justify-content: space-between;
        &:not(:last-of-type) {
          margin-bottom: 30px;
        }
        &::before {
          display: block;
          content: '';
          width: 50px;
          height: 50px;
          background: ext.$keyColor no-repeat center center / 26px auto;
        }
        &.user {
          &::before {
            background-image: url('/assets/images/content/content/ico_user.svg');
          }
        }
        &.password {
          &::before {
            background-image: url('/assets/images/content/content/ico_password.svg');
          }
        }
        &.mail {
          &::before {
            background-image: url('/assets/images/content/content/ico_mail.svg');
          }
        }
        input[type='text'],
        input[type='email'],
        input[type='tel'],
        input[type='number'],
        input[type='password'],
        input[type='date'],
        input[type='time'],
        textarea,
        select {
          width: calc(100% - 50px);
          padding-top: 10px;
          padding-bottom: 10px;
          border: none;
          border-radius: 0;
        }
      }
      .submitBtn {
        margin-top: 50px;
      }
    }
  }
  @media only screen and (max-width: (ext.$bp - 1)) {
    #loginVox {
      padding: 0 5vw;
      .formVox {
        width: auto;
      }
    }
  }
</style>
