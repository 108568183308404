<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  export let popup: string = '';
  export let activeImg: string = '';
  export let activeGalleryImg: number | null = 0;
  export let gallery: string[] = [];

  const dispatch = createEventDispatcher();

  const closePopup = (): void => {
    dispatch('closePopup', {
      popup: popup,
    });
  };

  const next = () => {
    // console.log(1);
    dispatch('nextGalleryImg', {
      index: activeGalleryImg !== gallery.length - 1 ? activeGalleryImg + 1 : 0,
    });
  };

  const prev = () => {
    dispatch('prevGalleryImg', {
      index: activeGalleryImg !== 0 ? activeGalleryImg - 1 : gallery.length - 1,
    });
  };
</script>

<div class="popupWrapper vertical">
  <div class="closeVox">
    <a href="xxx" class="popupCloseBt" on:click|preventDefault={closePopup}>
      <span><!-- --></span>
      <span><!-- --></span>
    </a>
  </div>
  <div class="contentWrapper">
    <div class="content" on:click={closePopup}>
      <!-- -->
      <figure class="galleryPopupImg img">
        <img src={activeImg} alt="" />
      </figure>
    </div>
    {#if gallery.length > 1}
      <div class="controls">
        <div class="btn prev" on:click={prev}>
          <span>Prev</span>
        </div>
        <div class="btn next" on:click={next}>
          <span>Next</span>
        </div>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .popupWrapper {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    .closeVox {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2200;
      width: 100%;
    }
    .content {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2001;
      .img {
        visibility: visible;
        &::before {
          content: 'LOADING...';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: -1;
          color: #fff;
        }
        img {
          max-width: calc(100vw - 100px);
          max-height: calc(100vh - 100px);
        }
      }
    }
    .controls {
      .btn {
        z-index: 2002;
      }
    }
  }
</style>
