<script lang="ts">
  import { APP_URL } from '../lib/config';
  import { navigate } from 'svelte-navigator';
  import TopicPathVox from '../components/TopicPathVox.svelte';
  import LoadingVox from '../components/LoadingVox.svelte';

  export let id: number;
  export let mode: string = 'new';

  let isEmptyUser: boolean = false;
  let isEditedUser: boolean = false;
  let isFailedEditedUser: boolean = false;
  let isShowDeleteUserPopup: boolean = false;
  let isSubmitting: boolean = false;
  let isDeleting: boolean = false;
  let isDeletedUser: boolean = false;
  let isFailedDeletedUser: boolean = false;
  let errorComment: string = '';
  const pageTitle: string =
    mode === 'new' ? '担当者情報登録' : '担当者情報編集';

  interface UserParams {
    id: number;
    login: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    status: boolean;
  }

  let formParams: UserParams;
  $: formParams = {
    id: 0,
    login: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    status: true,
  };

  $: user = (async () => {
    if (!id) return;
    const response = await fetch(
      `${APP_URL}get_user.php?id=${id}&role=manager`,
      {
        cache: 'no-cache',
      },
    );
    const res = await response.json();
    if (res.code !== 200) {
      isEmptyUser = true;
    } else {
      formParams.id = res.data.ID;
      formParams.login = res.data.user_login;
      formParams.firstName = res.data.first_name;
      formParams.lastName = res.data.last_name;
      formParams.email = res.data.user_email;
      formParams.status = res.data.user_status;
    }
    // console.log(res.data);
    return res.data;
  })();

  const submitUser = async () => {
    if (!formParams.login) return;

    isSubmitting = true;
    isEditedUser = false;
    isFailedEditedUser = false;

    if (
      formParams.password !==
      (<HTMLInputElement>document.getElementsByName('entryPass2')[0]).value
    ) {
      isSubmitting = false;
      isFailedEditedUser = true;
      errorComment = 'パスワード(確認)が正しくありません';
      return false;
    }
    const formData = new FormData();
    formData.append('login', formParams.login);
    formData.append('firstName', formParams.firstName);
    formData.append('lastName', formParams.lastName);
    formData.append('email', formParams.email);
    formData.append('password', formParams.password);
    formData.append('role', 'manager');
    formData.append('status', String(formParams.status));
    formData.append('mode', mode);
    if (mode === 'edit') {
      formData.append('id', String(formParams.id));
    }
    const response = await fetch(`${APP_URL}update_user.php`, {
      method: 'POST',
      // headers: [['content-type', 'multipart/form-data']],
      body: formData,
      cache: 'no-store',
    });
    const res = await response.json();
    // console.log(res);
    if (res.code === 200) {
      user = res.data;
      isSubmitting = false;
      isEditedUser = true;
      if (mode === 'new') {
        navigate('/editor/add', { replace: true });
      }
    } else {
      isSubmitting = false;
      isFailedEditedUser = true;
      errorComment = res.data;
    }
  };

  const deleteUser = async () => {
    isShowDeleteUserPopup = false;
    isDeleting = true;
    isDeletedUser = false;
    isFailedDeletedUser = false;

    const response = await fetch(`${APP_URL}delete_user.php?id=${id}`, {
      cache: 'no-store',
    });
    const res = await response.json();
    if (res.code === 200) {
      isDeleting = false;
      isDeletedUser = true;
      navigate('/editor/delete', { replace: true });
    } else {
      isDeleting = false;
      isFailedDeletedUser = true;
      errorComment = '担当者情報の削除に失敗しました';
    }
  };
</script>

<TopicPathVox {pageTitle} />

<div id="pageTitle">
  <h1>{pageTitle}</h1>
</div>
<!-- /pageTitle -->

<div id="content">
  {#await user}
    <LoadingVox />
  {:then}
    {#if isEmptyUser}
      <p class="nothing">担当者が見つかりませんでした。</p>
    {:else if isSubmitting}
      <LoadingVox />
    {:else}
      <div class="sectionVox">
        <div class="inner">
          {#if isEditedUser}
            <div class="completeMsg">
              <p>担当者情報の編集が完了しました。</p>
            </div>
          {/if}
          {#if isFailedEditedUser}
            <div class="errorMsg">
              <p>{errorComment}</p>
            </div>
          {/if}

          <div class="inputWrap">
            <div class="body">
              <h2 class="headStyle01 border"><span>担当者情報</span></h2>
              <div class="contVox">
                <label>
                  <h3 class="headStyle03">ログイン名</h3>
                  <input
                    type="text"
                    name="entryLogin"
                    bind:value={formParams.login}
                    readonly={mode === 'edit'}
                  />
                </label>
              </div>
              <!-- /contVox -->

              <div class="columnVox">
                <div class="contVox half">
                  <label>
                    <h3 class="headStyle03">パスワード</h3>
                    <input
                      type="password"
                      name="entryPass1"
                      bind:value={formParams.password}
                    />
                  </label>
                </div>
                <!-- /contVox -->

                <div class="contVox half">
                  <label>
                    <h3 class="headStyle03">パスワード(確認)</h3>
                    <input type="password" name="entryPass2" value="" />
                  </label>
                </div>
                <!-- /contVox -->
              </div>

              <div class="columnVox">
                <div class="contVox half">
                  <label>
                    <h3 class="headStyle03">担当者名(姓)</h3>
                    <input
                      type="text"
                      name="entryLastName"
                      bind:value={formParams.lastName}
                    />
                  </label>
                </div>
                <!-- /contVox -->

                <div class="contVox half">
                  <label>
                    <h3 class="headStyle03">担当者名(名)</h3>
                    <input
                      type="text"
                      name="entryFistName"
                      bind:value={formParams.firstName}
                    />
                  </label>
                </div>
                <!-- /contVox -->
              </div>

              <div class="contVox">
                <label>
                  <h2 class="headStyle03">メールアドレス</h2>
                  <input
                    type="email"
                    name="entryMail1"
                    bind:value={formParams.email}
                  />
                </label>
              </div>
              <!-- /contVox -->
            </div>
            <!-- /body -->

            <div class="sidebar">
              {#if mode === 'edit'}
                <div class="inputStatus">
                  <h2 class="headStyle03">ステータス</h2>
                  <dl class="visibility">
                    <dt>状態：</dt>
                    <dd>
                      <label>
                        <input
                          type="checkbox"
                          name="entryStatus"
                          bind:checked={formParams.status}
                        />
                        <span class="name">有効</span>
                      </label>
                    </dd>
                  </dl>
                  <dl class="id">
                    <dt>担当者ID：</dt>
                    <dd>{formParams.id}</dd>
                  </dl>
                </div>
                <!-- /inputStatus -->
              {/if}

              <div class="btnStyle01 submitBtn">
                <button name="entrySubmit" on:click|preventDefault={submitUser}
                  >{mode === 'new' ? '登録' : '更新'}</button
                >
              </div>

              {#if mode === 'edit'}
                <div class="deleteBtn">
                  <a
                    href="xxx"
                    class="popupBtItem"
                    on:click|preventDefault={() => {
                      isShowDeleteUserPopup = true;
                    }}>担当者情報削除</a
                  >
                </div>
              {/if}
            </div>
            <!-- /sidebar -->
          </div>
          <!-- /inputWrap -->
        </div>
      </div>
      <!-- /sectionVox -->

      {#if isShowDeleteUserPopup}
        <div class="popupWrapper vertical" id="popupWrapperDeleteUser">
          <div class="contentWrapper">
            <div class="content">
              <div class="deleteMsgVox">
                <h2 class="headStyle01">担当者を削除します</h2>
                <div class="btnArea">
                  <div class="btnStyle01 back white closeBtn">
                    <button
                      on:click|preventDefault={() =>
                        (isShowDeleteUserPopup = false)}
                      ><span>キャンセル</span></button
                    >
                  </div>
                  <div class="btnStyle01">
                    <button on:click|preventDefault={deleteUser}
                      ><span>削除する</span></button
                    >
                  </div>
                </div>
              </div>
              <!-- /deleteMsgVox -->
            </div>
          </div>
        </div>
      {/if}
    {/if}
  {:catch error}
    <p>An error occurred!</p>
  {/await}
</div>

<!-- /content -->
<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  input[type='text'],
  textarea {
    &:read-only {
      background-color: ext.$borderColorLight;
    }
  }
  .popupWrapper {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    .closeVox {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
    }
    .content {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &#popupWrapperDeleteUser {
      .content {
        height: 100vh;
      }
    }
  }
</style>
