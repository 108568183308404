<script lang="ts">
  import { APP_URL, ADMIN_URL, KARTE_SHOW_COUNT } from '../lib/config';
  import { link, navigate } from 'svelte-navigator';
  import TopicPathVox from '../components/TopicPathVox.svelte';
  import LoadingVox from '../components/LoadingVox.svelte';
  import PageControl from '../components/PageControl.svelte';
  import SearchForm from '../components/SearchForm.svelte';
  import CardList from '../components/CardList.svelte';

  export let paged: number = 1;
  export let query: string = '';

  const limit: number = KARTE_SHOW_COUNT;
  const url = new URL(location.href);
  const searchParams = url.searchParams;
  let isEmpty: boolean = false;
  if (searchParams.has('s')) {
    query = searchParams.get('s');
  }

  $: posts = (async () => {
    if (query) {
      paged = query ? 1 : paged;
      url.searchParams.set('s', query);
      window.history.pushState({}, '', `/karte/?s=${query}`);
    }
    const response = await fetch(
      `${APP_URL}get_karten.php?number=${limit}&paged=${paged}&search=${query}`,
      {
        cache: 'no-cache',
      },
    );
    const res = await response.json();
    // console.log(res);
    if (res.code === 401) {
      navigate('/login/logout', { replace: true });
    }
    isEmpty = res.code !== 200 || !res.data.count ? true : false;
    return res.data;
    // if (res.code === 401) {
    //   location.href = `${ADMIN_URL}wp-login.php`;
    // } else {
    //   return res.data;
    // }
  })();

  // const bodyClasses = 'karte index';
  const pageTitle = 'カルテ管理';
</script>

<TopicPathVox {pageTitle} />

<div id="pageTitle">
  <h1>{pageTitle}</h1>
  <SearchForm bind:query mode="karte" />
</div>
<!-- /pageTitle -->

<div id="content">
  <div class="statusVox">
    {#await posts then data}
      <div class="searchTotal">{data?.total} 件</div>
    {/await}
    <div class="btnStyle01">
      <a href="{ADMIN_URL}wp-admin/post-new.php?post_type=karte">カルテ作成</a>
    </div>
  </div>
  <!-- /statusVox -->

  <div class="sectionVox">
    <div class="inner">
      {#await posts}
        <LoadingVox mode="full" />
      {:then data}
        {#if isEmpty}
          <p class="nothing">カルテが見つかりませんでした。</p>
        {:else}
          <CardList posts={data.posts} />
          <PageControl {paged} maxPage={data.max_page} s={query} menu="karte" />
        {/if}
      {:catch error}
        <p>An error occurred!</p>
      {/await}
    </div>
  </div>
  <!-- /sectionVox -->
</div>

<!-- /content -->
