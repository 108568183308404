<script lang="ts">
  import { APP_URL } from '../lib/config';
  import LoadingVox from '../components/LoadingVox.svelte';

  export let id: number;
  let isEmpty: boolean = false;
  const pageTitle: string = '見積書フォーマット';

  $: post = (async () => {
    const response = await fetch(`${APP_URL}get_karte.php?id=${id}`, {
      cache: 'no-cache',
    });
    const res = await response.json();
    isEmpty = res.code !== 200 ? true : false;
    return res.data;
  })();

  document.getElementById('siteHeader').remove();
  document.getElementById('siteFooter').remove();
  document.body.style.backgroundColor = '#fff';
  document.getElementById('wrapper').style.padding = '50px 0';
  document.getElementById('container').style.paddingBottom = '0';
</script>

{#await post}
  <LoadingVox />
{:then data}
  {#if !isEmpty}
    <div id="estimateVox" class="estimateVox">
      <div class="logo">
        <img src="/assets/images/global/logo_full.svg" alt="鈴木人形" />
      </div>
      <time datetime={data.estimatedDateTime}>{data.estimated}</time>
      <h1 id="estimateTitle">
        <span class="name">{data.customer}</span>様
      </h1>
      <div class="txt">
        <p class="ti1em">
          この度は、修理のご依頼をいただきまして、誠に有難うございました。<br />
          ご依頼の人形をお預かりし、別紙に検証結果/修理ご提案詳細/お見積りを作成いたしました。<br
          />
          サービスご注意事項と併せてご確認くださいますよう、宜しくお願い申し上げます。
        </p>
        <p class="ti1em">
          ご案内の内容をご承認いただけました際は、ご入金のお手続きをいただきますよう、宜しくお願い申し上げます。<br
          />
          ご依頼をキャンセルされる場合は、お電話、またはEメール（info@suzuki-ningyo.com）にて、その旨をご連絡くださいますようお願いいたします。速やかにご返送させていただきます。（送料着払いにて発送）<br
          />
          本状送付後7日以上経過しても、お振込又はキャンセルのご連絡がない場合、申し訳ございませんがキャンセル扱いとし、ご返送させていただきます。
        </p>
        <p class="ti1em">
          尚、現在ご依頼が大変混雑しております。原則、作業はお申込み順に進めておりますが、年代物のご依頼品や、損傷が著しいご依頼品、大型のご依頼品につきましては、特に長期にてお預かりさせていただく場合がございます。<br
          />
          また、修理開始後でも、人形内部の状態や天候（気温/湿度）により、作業の進捗に影響がございますため、予告なくお預かり期間が延長される場合がございます。ご迷惑をお掛けいたしますが、人形の性質上、予めご了承くださいませ。
        </p>
        <p class="ti1em">
          再び美しく、永くお飾りいただけますよう、全てのご依頼品は、経済産業大臣認定の伝統工芸士が、心を込めて修理させていただきます。是非ご検討くださいませ。<br
          />
          その他ご不明な点は、お気軽に鈴木人形カスタマーサービスまでお問い合わせくださいますよう、何卒宜しくお願い申し上げます。
        </p>
        <p class="ti1em">
          今後とも、鈴木人形をご愛顧賜りますよう、宜しくお願い申し上げます。
        </p>
      </div>
      <div class="contactVox">
        <h2 class="title">
          鈴木人形カスタマーサービス<br />
          <span class="sub">人形修理専門【鈴木人形 人形修理工房】</span>
        </h2>
        <p class="phone">
          TEL.<span class="num">048-757-0223</span>
        </p>
        <p class="receptionHours">受付営業時間 9:00～17:30（土日祝除く）</p>
        <p>
          〒339-0057<br class="pcDspNone" /> 埼玉県さいたま市岩槻区本町3-5-16<br
          />
          E-mail：info@suzukI–ningyo.com
        </p>
      </div>
      <!-- /contactVox -->
    </div>

    <div id="estimateVox2" class="estimateVox">
      <div class="contVox">
        <h2 class="headStyle03 large">ご依頼品状態</h2>
        <table class="tableStyle03 vertical">
          <colgroup>
            <col class="w150" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>修理品目</th>
              <td>{data.item}</td>
            </tr>
            <tr>
              <th>修理内容</th>
              <td class="htmlContent">
                {@html data.estimate_content}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="totalCost">
          <p>
            修理合計代金
            <span class="price">{data.payment} 円</span>
            (税込)
          </p>
        </div>
      </div>
      <!-- /contVox -->

      <div class="contVox">
        <h2 class="headStyle03 large">お振込先</h2>
        <div class="voxStyle01">
          <p class="largeTxt">
            武蔵野銀行　岩槻支店　<br class="pcDspNone" />普通口座60326
            <br class="pcDspNone" />カブシキガイシャスズキニンギョウ
          </p>
        </div>
        <ul class="listStyle02">
          <li>振込手数料は別途ご負担ください。</li>
          <li>ご入金情報は金融機関より自動通知されます。</li>
          <li>ご入金後のキャンセル及びご返金は一切お受けできません。</li>
        </ul>
      </div>
      <!-- /contVox -->

      <div class="contVox">
        <h2 class="headStyle03 large">納品予定日</h2>
        <div class="voxStyle01">
          <p class="largeTxt">{data.delivery_date}</p>
        </div>
        <ul class="listStyle02">
          <li>本状通知より7日以内にお手続きいただいた場合の納期になります。</li>
          <li>ご入金確認順にて修理を開始させていただいております。</li>
          <li>
            修復過程における人形の状態により、期間が延長となる場合がございます。
          </li>
        </ul>
      </div>
      <!-- /contVox -->

      <div class="contVox">
        <h2 class="headStyle03 large">お引き渡し方法</h2>
        <p>{data.delivery_method}</p>
      </div>
      <!-- /contVox -->
    </div>

    <section id="estimateVox3" class="estimateVox">
      <h2 class="headStyle01 alignCenter">
        鈴木人形 人形修理工房<br />ご利用注意事項
      </h2>
      <ul class="cautionList">
        <li>
          全てのご依頼品は、必ず現物を検証後、お見積もり・納期をご案内いたしております。<br
          />写真や画像での判断は出来かねます。
        </li>
        <li>ご依頼のお申込みは、ご依頼品所有者様に限ります。</li>
        <li>
          人形修理作業につきましては、新品の状態に回復するものではございません。
        </li>
        <li>
          カビ・ほこり等の強固な汚れは、状態と場所によっては除去できない場合がございます。
        </li>
        <li>
          部品交換につきましては、同等品を用いますが、デザインや仕様は異なります。
        </li>
        <li>
          修理による価値損失が推定されるもの、極度の破損や劣化状況にある依頼品につきましては、修理をお受けいたしかねます。
        </li>
        <li>
          外見では判断できない人形内部の状態により、作業開始後でも、止むを得ず別途追加費用が発生する場合がございます。その際は別途お見積りをいたします。
        </li>
        <li>ご依頼品のお預かりお引き渡しは、一律宅配便にて承っております。</li>
        <li>
          修理開始後、人形の状態次第で、作業プランの変更が必要な場合がございます。そのため、予告なく完成予定日が遅れる場合がございます。
        </li>
        <li>
          本状送付日より7日以内にお振込み、又はキャンセルご連絡がない場合は、キャンセル扱いとして、お預かり品をご返送させていただきます。
        </li>
        <li>お支払（ご入金確認）後の作業開始となります。</li>
        <li>
          ご依頼品のお引き渡しは、当社指定契約配送業者より送料着払いにてお届けいたします。
        </li>
        <li>
          サービス特徴につき、ご入金後、納品後の補修内容のクレーム及び返金は一切お受けいたしかねます。
        </li>
        <li>
          ご注意事項に承認いただけない場合、サービスのご利用をお断りする場合がございます。
        </li>
        <li>
          修理ご依頼を以って、修理内容及び注意事項にご同意いただいたものとします。
        </li>
      </ul>
    </section>

    <div class="btnStyle01 long printVox">
      <a
        href="xxx"
        on:click|preventDefault={() => {
          window.print();
          return false;
        }}
      >
        <span>印刷する</span>
      </a>
    </div>
  {/if}
{:catch error}
  <p>An error occurred!</p>
{/await}

<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  .estimateVox {
    width: 800px;
    margin: 0 auto;
    position: relative;
    @media screen {
      &::after {
        display: block;
        color: ext.$keyColor;
        text-align: center;
        padding-top: 20px;
        margin: 100px 0;
        border-top: 1px dashed ext.$borderColorLight;
      }
    }
  }
  #estimateVox {
    @media screen {
      &::after {
        content: '1ページ目ここまで';
      }
    }
    .logo {
      text-align: center;
      margin-bottom: 50px;
      img {
        width: 300px;
      }
    }
    time {
      position: absolute;
      top: 0;
      right: 0;
    }
    #estimateTitle {
      font-size: 1.7rem;
      font-weight: bold;
      margin-bottom: 40px;
      .name {
        font-size: 2.8rem;
        margin-right: 5px;
      }
    }
    .txt {
      p {
        &:not(:last-child) {
          margin-bottom: 1.5em;
        }
      }
    }
    .contactVox {
      line-height: 1.6;
      text-align: right;
      margin-top: 60px;
      .title {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.3;
        .sub {
          font-size: 1.3rem;
          font-weight: normal;
        }
      }
      p {
        &:not(:last-child) {
          margin-bottom: 1em;
        }
      }
      .phone {
        font-size: 1.8rem;
        font-weight: bold;
        margin-top: 0.8em;
        margin-bottom: 0 !important;
        .num {
          font-size: 2.2rem;
        }
      }
      .receptionHours {
        font-size: 1.3rem;
      }
    }
  }
  #estimateVox2 {
    page-break-before: always;
    @media screen {
      &::after {
        content: '2ページ目ここまで';
      }
    }
    .totalCost {
      text-align: right;
      margin-top: 10px;
      p {
        display: inline-block;
        font-size: 1.8rem;
        font-weight: bold;
        border-bottom: 1px solid ext.$borderColor;
        .price {
          font-size: 2.5rem;
          margin-left: 15px;
        }
      }
    }
  }
  #estimateVox3 {
    page-break-before: always;
    @media screen {
      &::after {
        content: '3ページ目ここまで';
      }
    }
    .cautionList {
      list-style-type: square;
      padding: 0;
      margin: 50px 0 0 1.5em;
      li {
        font-size: 1.6rem;
        &:not(:last-child) {
          margin-bottom: 1em;
        }
      }
    }
  }
  .printVox {
    text-align: center;
  }
  @media print {
    .printVox {
      display: none;
    }
  }
</style>
