<script lang="ts">
  import { APP_URL } from '../lib/config';
  import LoadingVox from '../components/LoadingVox.svelte';

  export let id: number;

  let isEmpty: boolean = false;
  const pageTitle: string = '請求書フォーマット';

  $: post = (async () => {
    const response = await fetch(`${APP_URL}get_karte.php?id=${id}`, {
      cache: 'no-cache',
    });
    const res = await response.json();
    isEmpty = res.code !== 200 ? true : false;
    return res.data;
  })();

  document.getElementById('siteHeader').remove();
  document.getElementById('siteFooter').remove();
  document.body.style.backgroundColor = '#fff';
  document.getElementById('wrapper').style.padding = '30px 0';
  document.getElementById('container').style.paddingBottom = '0';
</script>

{#await post}
  <LoadingVox />
{:then data}
  {#if !isEmpty}
    <section id="invoiceVox">
      <header>
        <div>
          <h2 class="title">請求書</h2>
        </div>
        <div class="createVox">
          <p class="personVox">
            作成日：<span class="dateVox">{data.invoice_create_date}</span>
            <span class="person">{data.manager_lastname}</span>
          </p>
          <p class="logo">
            <img
              src="/assets/images/global/logo_b.svg"
              alt="株式会社鈴木人形"
            />
            株式会社 鈴木人形
          </p>
          <p>
            〒339-0057 埼玉県さいたま市岩槻区本町3-5-16<br />
            鈴木人形 人形・文化財修復センター<br />
            担当：{data.manager_lastname}
          </p>
        </div>
      </header>
      <div class="inner">
        <div class="thanksVox">
          <p>下記のとおり、御請求申し上げます。</p>
        </div>
        <!-- /thanksVox -->
        <div class="outlineVox">
          <div class="totalCost">
            <p>
              修理合計代金
              <span class="price">{data.payment} 円</span>
              (税込)
            </p>
          </div>
          <div class="customerVox">
            <p class="name">{data.customer} 様</p>
          </div>
        </div>
        <!-- /outlineVox -->

        <table class="tableStyle03 vertical">
          <colgroup>
            <col class="w150" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>修理品目</th>
              <td>{data.item}</td>
            </tr>
            <tr>
              <th>修理内容</th>
              <td class="htmlContent">
                {@html data.content}
              </td>
            </tr>
            <tr>
              <th>振込先</th>
              <td>
                武蔵野銀行　岩槻支店　普通060326　カブシキガイシャスズキニンギョウ
              </td>
            </tr>
            <tr>
              <th>振込期限</th>
              <td>{data.payment_limit_date}</td>
            </tr>
            <tr>
              <th>備考/修理後記</th>
              <td>
                {@html data.customer_info.user_note}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <!-- /invoiceVox -->

    <div class="btnStyle01 long printVox">
      <a
        href="xxx"
        on:click|preventDefault={() => {
          window.print();
          return false;
        }}
      >
        <span>印刷する</span>
      </a>
    </div>
  {/if}
{:catch error}
  <p>An error occurred!</p>
{/await}

<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  #invoiceVox {
    width: 1000px;
    margin: 0 auto;
    position: relative;
    header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .title {
        font-size: 3rem;
        font-weight: bold;
        line-height: 1.3;
        .sub {
          font-size: 2rem;
        }
      }
      .createVox {
        font-size: 1.3rem;
        text-align: right;
        .personVox {
          display: flex;
          align-items: center;
          justify-content: end;
          .dateVox {
            width: 6.5em;
          }
        }
        .person {
          display: inline-block;
          color: #ff0000;
          font-size: 1.2rem;
          line-height: 1.3;
          vertical-align: middle;
          padding: 2px 10px;
          margin-left: 5px;
          border: 1px solid #ff0000;
          border-radius: 10em;
        }
      }
      .logo {
        display: flex;
        align-items: center;
        padding: 1em 0;
        img {
          width: 200px;
          margin-right: 1em;
        }
      }
    }
    .thanksVox {
      text-align: center;
      padding: 5px 20px;
      margin-bottom: 30px;
      border: 1px solid ext.$borderColor;
    }
    .outlineVox {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 30px;
      .totalCost {
        p {
          display: inline-block;
          font-size: 1.6rem;
          font-weight: bold;
          border-bottom: 1px solid ext.$borderColor;
          .price {
            font-size: 2.2rem;
            margin-left: 15px;
          }
        }
      }
      .customerVox {
        .name {
          font-size: 1.8rem;
          font-weight: bold;
        }
      }
    }
  }
  .printVox {
    text-align: center;
    margin-top: 50px;
  }
  @media print {
    .printVox {
      display: none;
    }
  }
</style>
