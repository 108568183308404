<script lang="ts">
  import { APP_URL, ADMIN_URL } from '../lib/config';
  import { Link } from 'svelte-navigator';
  import TopicPathVox from '../components/TopicPathVox.svelte';
  import LoadingVox from '../components/LoadingVox.svelte';
  import CardList from '../components/CardList.svelte';

  export let id: number;

  let isEmpty: boolean = false;
  let isEmptyUser: boolean = false;
  let userName: string = '';
  const pageTitle: string = '顧客詳細';

  $: user = (async () => {
    const response = await fetch(`${APP_URL}get_user.php?id=${id}`, {
      cache: 'no-cache',
    });
    const res = await response.json();
    if (res.code === 200) {
      userName = res.data.display_name;
    } else {
      isEmptyUser = true;
    }
    return res.data;
  })();

  $: posts = (async () => {
    const response = await fetch(`${APP_URL}get_karten.php?user_id=${id}`, {
      cache: 'no-cache',
    });
    const res = await response.json();
    isEmpty = res.code !== 200 ? true : false;
    return res.data;
  })();
</script>

<TopicPathVox {pageTitle} />

<div id="pageTitle">
  <h1>{pageTitle}</h1>
</div>
<!-- /pageTitle -->

<div id="content">
  {#await user}
    <LoadingVox />
  {:then data}
    {#if isEmptyUser}
      <p class="nothing">顧客が見つかりませんでした。</p>
    {:else}
      <section class="sectionVox">
        <header>
          <h2 class="headStyle01">顧客情報</h2>
          <div class="iconBtn edit">
            <Link to="/customer/{id}/edit">
              <span>顧客編集</span>
            </Link>
          </div>
        </header>
        <div class="contVox">
          <table class="tableStyle02">
            <tbody>
              <tr>
                <th>ID</th>
                <td>{data.ID} {data.user_status ? '(有効)' : '(無効)'}</td>
              </tr>
              <tr>
                <th>顧客名</th>
                <td>{userName} 様</td>
              </tr>
              <tr>
                <th>住所</th>
                <td>{data.user_address}</td>
              </tr>
              <tr>
                <th>電話番号</th>
                <td>{data.user_phone}</td>
              </tr>
              <tr>
                <th>メールアドレス</th>
                <td>
                  <a href="mailto:{data.user_email}" target="_blank"
                    >{data.user_email}</a
                  ></td
                >
              </tr>
              <tr>
                <th>会社・団体名</th>
                <td>{data.user_company}</td>
              </tr>
              <tr>
                <th>備考</th>
                <td>{@html data.user_note}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /contVox -->
      </section>
    {/if}
  {:catch error}
    <p>An error occurred!</p>
  {/await}
  <div class="sectionVox">
    {#if userName}
      <header class="kartenHead">
        <h2 class="headStyle01">{userName} 様のカルテ</h2>
        <div class="iconBtn edit">
          <a href="{ADMIN_URL}wp-admin/post-new.php?post_type=karte&user={id}">
            <span>カルテ作成</span>
          </a>
        </div>
      </header>
    {/if}
    <div class="inner">
      {#await posts}
        <LoadingVox />
      {:then data}
        {#if isEmpty}
          <p class="nothing">カルテが見つかりませんでした。</p>
        {:else}
          <CardList posts={data.posts} />
        {/if}
      {:catch error}
        <p>An error occurred!</p>
      {/await}
    </div>
  </div>
  <!-- /sectionVox -->
</div>

<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  .sectionVox {
    > header {
      margin-bottom: 15px;
    }
  }
  @media only screen and (max-width: (ext.$bp - 1)) {
    #customerList {
      overflow-x: auto;
      table {
        width: 1000px;
      }
    }
    // .kartenHead {
    //   display: block;
    //   .iconBtn {
    //     text-align: right;
    //   }
    // }
  }
</style>
