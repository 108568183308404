<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  interface Gallery {
    thumbnail: string;
    full: string;
  }
  export let images: Gallery[];
  export let activeGalleryImg: number | null = null;

  const dispatch = createEventDispatcher();

  const showPopup = () => {
    dispatch('showPopup', {
      popup: 'galleryImg',
    });
  };
  const changeActiveGalleryImg = () => {
    dispatch('changeActiveGalleryImg', {
      index: activeGalleryImg,
    });
  };
</script>

<!-- <figure class="main" id="js-galleryMain">
  <a href="xxx" class="popupBtItem icoZoom" on:click|preventDefault={showPopup}
    ><img src={images[activeGalleryImg].full} alt="" /></a
  >
</figure> -->
<div class="thm" id="js-galleryThm">
  {#each images as image, index}
    <figure class="item" class:is-active={activeGalleryImg === index}>
      <a
        href="xxx"
        data-thumbnail={image.thumbnail}
        data-fullImg={image.full}
        on:click|preventDefault={() => {
          activeGalleryImg = index;
          changeActiveGalleryImg();
          showPopup();
        }}
        class="icoZoom"
      >
        <img src={images[index].thumbnail} alt="" />
      </a>
    </figure>
  {/each}
</div>

<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  .main {
    width: 50%;
  }
  .thm {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    > .item {
      width: 31%;
      margin-top: 3.5%;
      &:not(:nth-of-type(3n)) {
        margin-right: 3.5%;
      }
      a {
        @include ext.objectFitImg();
        @include ext.opacityFade();
        display: block;
        height: 0;
        padding-top: 75%;
        background: #fff;
      }
      &.is-active {
        a {
          box-shadow: 0 0 0 3px ext.$borderColor inset;
        }
        img {
          opacity: 0.5;
        }
      }
    }
  }
</style>
