<script lang="ts">
  import { Link } from 'svelte-navigator';

  interface CustomerParam {
    ID: number;
    user_status: boolean;
    display_name: string;
    user_address: string;
    user_phone: string | number;
    user_email: string;
  }

  export let customers: CustomerParam[];
</script>

<div id="customerList">
  <table class="tableStyle01">
    <thead>
      <tr>
        <th scope="row" class="id">ID</th>
        <th scope="row" class="name">顧客名</th>
        <th scope="row" class="address">住所</th>
        <th scope="row" class="mail">メールアドレス</th>
        <th scope="row" class="edit">編集</th>
      </tr>
    </thead>
    <tbody>
      {#each customers as customer}
        <tr class:is-disabled={!customer.user_status}>
          <td>{customer.ID}</td>
          <td>
            <Link to="/customer/{customer.ID}">
              {customer.display_name} 様
            </Link>
          </td>
          <td>{customer.user_address}</td>
          <td>{customer.user_email}</td>
          <td>
            <a href="/customer/{customer.ID}/edit" class="icoEdit">
              <img
                src="/assets/images/content/content/ico_edit.svg"
                alt="編集"
              />
            </a>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>

<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  #customerList {
    .tableStyle01 {
      tbody {
        tr {
          &.is-disabled {
            th,
            td:not(.edit) {
              // background-color: ext.$borderColorLight;
              color: rgba(ext.$textColor, 0.25);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: (ext.$bp - 1)) {
    #customerList {
      overflow-x: auto;
      table {
        width: 1000px;
      }
    }
  }
</style>
