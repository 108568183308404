<script lang="ts">
  import { Link } from 'svelte-navigator';

  export let mode: string = 'karte';
  export let query: string = '';
</script>

<div class="searchVox">
  <form action="/{mode}/" method="get">
    <div class="input">
      <input
        type="text"
        name="s"
        class="keyword"
        placeholder={mode === 'karte'
          ? 'ID / 修理品目 / 顧客名 / 修理内容 / 担当者名'
          : 'ID / 顧客名 / メールアドレス / 住所 / 会社 / 団体名'}
        bind:value={query}
      />
      <input type="submit" class="search" />
    </div>
    {#if query}
      <div class="reset">
        <Link to="/{mode}/" on:click={() => (query = '')}>
          <span>絞り込み解除</span>
        </Link>
      </div>
    {/if}
  </form>
</div>
<!-- /searchVox -->
