<script lang="ts">
  import { APP_URL } from '../lib/config';
  import LoadingVox from '../components/LoadingVox.svelte';

  export let id: number;

  let isEmpty: boolean = false;
  const pageTitle: string = '納品書フォーマット';

  $: post = (async () => {
    const response = await fetch(`${APP_URL}get_karte.php?id=${id}`, {
      cache: 'no-cache',
    });
    const res = await response.json();
    isEmpty = res.code !== 200 ? true : false;
    return res.data;
  })();

  document.getElementById('siteHeader').remove();
  document.getElementById('siteFooter').remove();
  document.body.style.backgroundColor = '#fff';
  document.getElementById('wrapper').style.padding = '50px 0';
  document.getElementById('container').style.paddingBottom = '0';
</script>

{#await post}
  <LoadingVox />
{:then data}
  {#if !isEmpty}
    <section id="deliveryVox">
      <header>
        <h2 class="title">
          <span class="sub">鈴木人形　人形修理工房</span><br />
          リペアサービスレポートシート
        </h2>
        <div class="createVox">
          <p>
            作成日：{data.delivery_create_date}
            <span class="person">{data.manager_lastname}</span>
          </p>
        </div>
      </header>
      <div class="inner">
        <div class="thanksVox">
          <p>
            この度は、鈴木人形
            人形修理工房をご利用いただきまして、誠に有難うございました。
          </p>
        </div>
        <!-- /thanksVox -->
        <div class="outlineVox">
          <div class="totalCost">
            <p>
              修理合計代金
              <span class="price">{data.payment} 円</span>
              (税込)
            </p>
          </div>
          <div class="customerVox">
            <p class="name">{data.customer} 様</p>
            <table class="tableTransparent">
              <tr>
                <th>ご住所：</th>
                <td>{data.customer_info.user_address}</td>
              </tr>
              <tr>
                <th>電話番号：</th>
                <td>{data.customer_info.user_phone}</td>
              </tr>
            </table>
          </div>
        </div>
        <!-- /outlineVox -->

        <table class="tableStyle03 vertical">
          <colgroup>
            <col class="w150" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>お預かり日</th>
              <td>{data.receiving_date}</td>
            </tr>
            <tr>
              <th>修理品目</th>
              <td>{data.item}</td>
            </tr>
            <tr>
              <th>修理内容</th>
              <td class="htmlContent">
                {@html data.content}
              </td>
            </tr>
            <tr>
              <th>備考/修理後記</th>
              <td>
                {@html data.customer_info.user_note}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <!-- /deliveryVox -->

    <div class="btnStyle01 long printVox">
      <a
        href="xxx"
        on:click|preventDefault={() => {
          window.print();
          return false;
        }}
      >
        <span>印刷する</span>
      </a>
    </div>
  {/if}
{:catch error}
  <p>An error occurred!</p>
{/await}

<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  #deliveryVox {
    width: 1000px;
    margin: 0 auto;
    position: relative;
    header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .title {
        font-size: 3rem;
        font-weight: bold;
        line-height: 1.3;
        .sub {
          font-size: 2rem;
        }
      }
      .createVox {
        font-size: 1.3rem;
        text-align: right;
        .person {
          display: inline-block;
          color: #ff0000;
          font-size: 1.2rem;
          line-height: 1.3;
          vertical-align: middle;
          padding: 2px 10px;
          margin-left: 5px;
          border: 1px solid #ff0000;
          border-radius: 10em;
        }
      }
    }
    .thanksVox {
      text-align: center;
      padding: 5px 20px;
      margin-bottom: 20px;
      border: 1px solid ext.$borderColor;
    }
    .outlineVox {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 20px;
      .totalCost {
        p {
          display: inline-block;
          font-size: 1.6rem;
          font-weight: bold;
          border-bottom: 1px solid ext.$borderColor;
          .price {
            font-size: 2.2rem;
            margin-left: 15px;
          }
        }
      }
      .customerVox {
        .name {
          font-size: 1.8rem;
          font-weight: bold;
        }
      }
    }
  }
  .printVox {
    text-align: center;
    margin-top: 50px;
  }
  @media print {
    .printVox {
      display: none;
    }
  }
</style>
