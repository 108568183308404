<script lang="ts">
  import { link } from 'svelte-navigator';

  interface Post {
    commentCount: number;
    id: number;
    thumbnail: string;
    item: string;
    datetime: string;
    date: string;
    customer: string;
    payment_date: string;
  }
  export let post: Post;
</script>

<div class="item">
  <span class="commentCount">{post.commentCount}</span>
  <a href="/karte/{post.id}" use:link>
    <figure class="img">
      {#if post.thumbnail}
        <img src={post.thumbnail} alt={post.item} />
      {:else}
        <img
          src="/assets/images/content/content/noimg.svg"
          alt="dummy"
          class:noimg={!post.thumbnail}
        />
      {/if}
    </figure>
    <div class="txt">
      <span class="number">No.{post.id}</span>
      <h2 class="title">{post.item}</h2>
      <time datetime={post.datetime}>{post.date}</time>
    </div>
    <div class="customer">
      <p class="name">{post.customer}</p>
      {#if post.payment_date}
        <span class="status finished">お振込済</span>
      {:else}
        <span class="status unpaid">未入金</span>
      {/if}
    </div>
  </a>
</div>

<!-- /item -->
<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  .item {
    width: 23.4%;
    max-width: 280px;
    margin-bottom: 2.5%;
    position: relative;
    &:not(:nth-of-type(4n)) {
      margin-right: 2.1333%;
    }
    .commentCount {
      position: absolute;
      z-index: 1;
      top: -1em;
      right: -1em;
      background-color: ext.$baseColor;
      color: #fff;
      font-size: 1rem;
      border-radius: 100vh;
      width: 3em;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .in,
    a {
      display: block;
      color: ext.$textColor;
      text-decoration: none;
      background: #fff;
      .img {
        @include ext.objectFitImg();
        height: 0;
        padding-top: 75%;
        .noimg {
          filter: opacity(30%);
        }
      }
      .txt {
        line-height: 1.5;
        padding: 10px 20px;
        .number {
          display: block;
          font-size: 1.1rem;
          font-weight: 300;
          margin-bottom: 5px;
        }
        .title {
          font-size: 1.6rem;
          font-weight: bold;
          margin-bottom: 15px;
        }
        time {
          color: ext.$keyColor;
          font-size: 1.1rem;
          font-weight: 300;
        }
      }
      .customer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.3rem;
        line-height: 1.3;
        padding: 10px 10px 10px 20px;
        border-top: 1px solid ext.$borderColorLight;
        .name {
          width: calc(100% - 80px);
        }
      }
    }
    a {
      @include ext.opacityFade();
    }
  }
  @media only screen and (max-width: (ext.$bp - 1)) {
    .item {
      width: 48%;
      max-width: 100%;
      margin-bottom: 5%;
      &:not(:nth-of-type(4n)) {
        margin-right: 0;
      }
      .in,
      a {
        .txt {
          padding: 10px;
          .number {
            font-size: 1rem;
          }
          .title {
            font-size: 1.5rem;
            line-height: 1.5;
            margin-bottom: 10px;
          }
          time {
            font-size: 1rem;
          }
        }
        .customer {
          font-size: 1.2rem;
          padding: 5px 10px;
          .name {
            width: calc(100% - 60px);
          }
        }
      }
    }
  }
</style>
