<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  interface ContactImg {
    thumbnail: string;
    full: string;
  }
  export let images: ContactImg[];
  export let activeContactImg: number | null = null;
  export let text: string = '';

  const dispatch = createEventDispatcher();
  const showPopup = () => {
    dispatch('showPopup', {
      popup: 'contactImg',
    });
  };
  const changeActiveContactImg = () => {
    dispatch('changeActiveContactImg', {
      index: activeContactImg,
    });
  };
</script>

<div class="firstContactVox">
  <div class="thm" id="js-firstImgThm">
    {#each images as image, index}
      <figure class="item" class:is-active={activeContactImg === index}>
        <a
          href="xxx"
          data-thumbnail={image.thumbnail}
          data-fullImg={image.full}
          on:click|preventDefault={() => {
            activeContactImg = index;
            changeActiveContactImg();
            showPopup();
          }}
          class="icoZoom"
        >
          <img src={images[index].thumbnail} alt="" />
        </a>
      </figure>
    {/each}
  </div>
  <div class="txt">
    <p>
      {@html text}
    </p>
  </div>
</div>

<!-- /imgTxtVox -->
<style lang="scss">
  @use '../../public/assets/scss/extension/' as ext;

  .firstContactVox {
    .thm {
      width: 45.8%;
      max-width: 550px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 30px;
      > .item {
        width: 31%;
        &:not(:nth-of-type(3n)) {
          margin-right: 3.5%;
        }
        a {
          @include ext.objectFitImg();
          @include ext.opacityFade();
          display: block;
          height: 0;
          padding-top: 75%;
          background: #fff;
        }
        &.is-active {
          a {
            box-shadow: 0 0 0 3px ext.$borderColor inset;
          }
          img {
            opacity: 0.5;
          }
        }
      }
    }
  }
  @media only screen and (max-width: (ext.$bp - 1)) {
    .firstContactVox {
      .thm {
        width: auto;
        max-width: none;
      }
    }
  }
</style>
